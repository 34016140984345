import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CircleTimer = props => {
  const total = props.totalTime || 60;

  const calculateDashArray = () => {
    const circleDasharray = `${((props.timer / total) * 283).toFixed(0)} 283`;

    return circleDasharray;
  };

  return (
    <BaseTimer showCounter={props.showCounter}>
      <BaseTimerSvg
        showCounter={props.showCounter}
        className="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <BaseTimerCircle>
          <Circle
            cx="50"
            cy="50"
            r={props.showCounter !== false ? '45' : '40'}
            timerBackgroundColor={props.timerBackgroundColor}
            showCounter={props.showCounter}
          />
          {props.showCounter !== false ? (
            <CircleRemaining
              progressColor={props.progressColor}
              strokeDasharray={calculateDashArray()}
              d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
            />
          ) : (
            <Circle
              cx="50"
              cy="50"
              r="40"
              timerBackgroundColor={props.progressColor}
              strokeDasharray={calculateDashArray()}
              total={total}
              showCounter={props.showCounter}
            />
          )}
        </BaseTimerCircle>
      </BaseTimerSvg>
      {props.showCounter !== false && <LabelTimer timerColor={props.timerColor}>{props.timer}</LabelTimer>}
    </BaseTimer>
  );
};

CircleTimer.propTypes = {
  timer: PropTypes.string,
};

export default CircleTimer;

const BaseTimer = styled.div`
  position: relative;
  display: flex;
  height: ${({ showCounter }) => (showCounter !== false ? '80px' : '30px')};
  width: ${({ showCounter }) => (showCounter !== false ? '80px' : '30px')};
  @media screen and (max-width: 374px) {
    height: ${({ showCounter }) => (showCounter !== false ? '60px' : '30px')};
    width: ${({ showCounter }) => (showCounter !== false ? '60px' : '30px')};
  }
`;

const BaseTimerSvg = styled.svg`
  transform: ${({ showCounter }) => (showCounter !== false ? `scaleX(-1)` : `rotate(270deg) scaleY(-1)`)};
`;

const BaseTimerCircle = styled.g`
  fill: none;
  stroke: none;
`;

const Circle = styled.circle`
  stroke-width: ${({ showCounter }) => (showCounter !== false ? '10px' : '20px')};
  stroke: ${({ timerBackgroundColor }) => timerBackgroundColor};
  & :nth-child(2) {
    stroke: ${({ timerBackgroundColor }) => timerBackgroundColor};
    stroke-dashoffset: calc(440 - (440 * ${({ total }) => total}) / ${({ total }) => total});
    stroke-dasharray: ${({ strokeDasharray }) => strokeDasharray};
  }
`;

const CircleRemaining = styled.path`
  stroke-width: 10px;
  stroke-linecap: square;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: ${({ theme, progressColor }) => (progressColor ? progressColor : theme.primaryColor)};
`;

const LabelTimer = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 500;
  font-size: 30px;
  color: #808080;
  margin-left: -17px;
  margin-top: -15px;
  height: 30px;
  line-height: 30px;
  color: ${({ timerColor, theme }) => theme.fontColor || timerColor};

  @media screen and (max-width: 374px) {
    font-size: 24px;
    margin-left: -13px;
    margin-top: -15px;
  }
`;
