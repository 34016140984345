import { useState, useEffect } from 'react';
import { getTimeFromSeconds, parseDateTime } from './helpers/time';

export const useTimer = timeLeft => {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => setTimer(calculateRemainingTime()), 1000);
    return () => clearInterval(interval);
  }, []);

  const calculateRemainingTime = () => {
    const endTime = parseDateTime(timeLeft);
    const remainingTime = Date.parse(endTime) - Date.parse(new Date());

    return remainingTime;
  };

  return getTimeFromSeconds(timer);
};
