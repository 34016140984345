/* istanbul ignore file */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { Text } from '@catalogo/theme-text';
import { formatPrice } from '@catalogo/core-helpers/price';
import { Stars } from '@catalogo/theme-stars';
import { getIn } from 'seamless-immutable';
import PropTypes from 'prop-types';
import { Nav } from '@catalogo/core-nav';
import AmeWhite from '@catalogo/ui-americanas-desktop-svg/ame-white.svg';
import TruckStore from '@catalogo/ui-americanas-desktop-svg/truck-store.svg';
import BrandCard from '@catalogo/ui-americanas-desktop-svg/brand-card.svg';
import AmeSymbol from '@catalogo/ui-americanas-desktop-svg/ame-symbol.svg';
import { getImage } from '@catalogo/service-americanas-common/src/helpers/image';
import { Observer } from '@catalogo/theme-observer';
import Skeleton from '@catalogo/theme-skeleton';
import { getProductPathname } from '@catalogo/core-product/src';

const getPrice = (bestPaymentOption, cartaoMarca, offerHighlight, offers) => {
  const { type } = bestPaymentOption;
  if (type === 'BOLETO' || type === 'PIX') {
    return {
      type,
      listPrice: bestPaymentOption.listPrice,
      price: bestPaymentOption.price,
      cashback: bestPaymentOption.cashback,
      condition: null,
      discount: getDiscount(getIn(bestPaymentOption, ['discount', 'rate'], 0), offers),
    };
  }

  if (offerHighlight === 'Finance') {
    return formatPaymentOption(cartaoMarca, offers);
  }

  return formatPaymentOption(bestPaymentOption, offers);
};

const getIcon = offerHighlight => {
  switch (offerHighlight) {
    case 'Cashback':
      return AmeWhite;
    case 'Frete Grátis':
      return TruckStore;
    case 'Finance':
      return BrandCard;
    default:
      return null;
  }
};

const getDiscount = (discount, offers) => {
  if (discount && discount > 0) {
    return discount;
  }
  return getIn(offers, ['discount', 'rate'], 0);
};

const formatPaymentOption = (paymentOption, offers) => {
  const minQuantity = getIn(paymentOption, ['minQuantity', 0], {});
  if (paymentOption?.price > minQuantity.value) {
    return {
      type: paymentOption.type,
      listPrice: paymentOption.listPrice,
      price: minQuantity.value,
      cashback: minQuantity.cashback,
      condition: '1x',
      discount: getDiscount(getIn(minQuantity, ['discount', 'rate'], 0), offers),
    };
  }
  return {
    ...paymentOption,
    discount: getDiscount(getIn(paymentOption, ['discount', 'rate'], 0), offers),
  };
};

const renderInstallment = (payment, offerHighlight) => {
  if (payment.type === 'BOLETO') {
    return 'no Boleto';
  }
  if (payment.type === 'PIX') {
    return 'com pix';
  }

  const card = offerHighlight === 'no cartão';

  const ame = offerHighlight === 'Cashback' ? 'com ame' : '';

  if (payment.condition === '1x') {
    return `1x de R$ ${formatPrice(payment.price)} sem juros ${card} ${ame}`;
  }

  const maxQuantity = getIn(payment, ['maxQuantity', 0], {});
  return `${maxQuantity.quantity}x de R$ ${formatPrice(maxQuantity.value)} sem juros ${card} ${ame}`;
};

const mountTitle = (offerHighlight, payment, hasDiscount, discount, text) => {
  if (offerHighlight === 'Somente Texto') {
    return text;
  }

  if (offerHighlight === 'Cashback' && payment.cashback) {
    return `${payment.cashback.rate}% de cashback`;
  }

  if (offerHighlight === 'Frete Grátis') {
    return 'Frete Grátis';
  }

  if (hasDiscount) {
    return `${discount}% de desconto`;
  }

  return text;
};

const renderSalesPrice = (price, salesPrice) => {
  if (salesPrice && salesPrice > price) {
    return <Del>R$ {formatPrice(salesPrice)}</Del>;
  }
  return null;
};

const renderListPrice = (price, listPrice) => {
  if (listPrice && listPrice > price) {
    return <Del>R$ {formatPrice(listPrice)}</Del>;
  }
  return null;
};

const getOfferId = offer => {
  if (!offer) {
    return '';
  }
  const { offerId } = offer;
  return offerId ? offerId : '';
};

const getSellerId = sellerId => (sellerId ? sellerId : '');

const getParamsUrl = (paramsArray, hasParameter, ignoreSeparator = false) => {
  const params = paramsArray.reduce((acc, param, index) => {
    const key = Object.keys(param)[0];
    if (param[key]) {
      const separator = ignoreSeparator ? '' : '&';
      if (index === 0 && !hasParameter) {
        return (acc += `${separator?.replace('&', '?')}${key}=${param[key]}`);
      }
      return (acc += `${separator}${key}=${param[key]}`);
    }
    return acc;
  }, '');

  return params;
};

const getLink = (link, productId, sellerId, offerId, productSlug) => {
  const paramsArray = [{ sellerId: sellerId }, { offerId: offerId }];
  const hasParameter = link ? !!new URL(link).search : false;
  const pathname = getProductPathname({ id: productId, slug: productSlug });
  const noLink = `${pathname}${getParamsUrl(paramsArray, hasParameter, true)}`;

  return link ? `${link}${getParamsUrl(paramsArray, hasParameter)}` : noLink;
};

const getEventStamp = offer => {
  const eventStamp = getIn(offer, ['stamps', 'events', 0], false);
  return eventStamp ? `https://images-americanas.b2w.io/selob2c/americanas/${eventStamp}.png` : '';
};

// eslint-disable-next-line max-statements
export const LazyProduct = ({ product, offerHighlight, productId, text, link, sellerId }) => {
  const sources = { mobile: { src: getImage(getIn(product, ['images', 0], {}), 'medium') || '', ratio: '1:1' } };

  const offer = getIn(product, ['offers', 'result', 0]);
  const bestPaymentOption = getIn(offer, ['bestPaymentOption']);
  const cartaoMarca = getIn(offer, ['cartaoMarca']);
  const salesPrice = getIn(offer, ['salesPrice']);
  const listPrice = getIn(offer, ['listPrice']);
  const payment = offer ? getPrice(bestPaymentOption, cartaoMarca, offerHighlight, offer) : {};
  const installment = offer ? renderInstallment(payment, offerHighlight) : '';
  const eventStamp = getEventStamp(offer);
  const [isVisible, setIsVisible] = useState(false);

  const renderTitle = () => {
    const discount = getIn(payment, ['discount'], {});
    const hasDiscount = discount > 0;

    return mountTitle(offerHighlight, payment, hasDiscount, discount, text);
  };

  const average = getIn(product, ['rating', 'average'], 0);
  const { recommendations } = getIn(product, ['rating']) || {};
  const Icon = getIcon(offerHighlight);
  const productSlug = getIn(product, ['slug'], '');

  return (
    <>
      {!isVisible && <Skeleton width="296px" height="458px" spinner border="16px" />}
      <Observer onceVisible={setIsVisible} rootMargin="150px 150px 150px 150px">
        <Card isVisible={isVisible}>
          {isVisible && (
            <NavUI to={getLink(link, productId, getSellerId(sellerId), getOfferId(offer), productSlug)}>
              <Wrapper>
                <Title type={offerHighlight}>
                  <WrapperIcon>{Icon && <Icon width={24} height={18} fill="#ffffff" />}</WrapperIcon>
                  {renderTitle()}
                </Title>
                <WrapperProduct>
                  <WrapperImage>
                    <LazyPicture sources={sources} alt={product.name} />
                    {eventStamp && (
                      <WrapperImgEvent>
                        <img src={eventStamp} alt="imagem do produto do evento em oferta" />
                      </WrapperImgEvent>
                    )}
                  </WrapperImage>
                  <ProductName numberOfLines={2}>{product.name}</ProductName>
                  <WrapperStars>
                    <Stars rating={average} />
                    {average && ` (${recommendations})`}
                  </WrapperStars>
                  {offer && (
                    <>
                      {renderSalesPrice(payment.price, salesPrice) || renderListPrice(payment.price, listPrice)}
                      <Price>{`R$ ${formatPrice(payment.price)}`}</Price>

                      <Installment>{installment}</Installment>
                      {payment.cashback && payment.cashback.rate > 1 && (
                        <Cashback>
                          <AmeSymbolUI width={16} height={16} />
                          {`Receba R$ ${formatPrice(payment.cashback.value)}`}
                          <CashbackRate>{`(${payment.cashback.rate}% de volta)`}</CashbackRate>
                        </Cashback>
                      )}
                    </>
                  )}
                  {!offer && <OutOfStockText>Infelizmente não temos esse produto em estoque</OutOfStockText>}
                </WrapperProduct>
              </Wrapper>
            </NavUI>
          )}
        </Card>
      </Observer>
    </>
  );
};

export const Product = ({ product, offerHighlight, productId, text, link, sellerId }) => {
  const sources = { mobile: { src: getImage(getIn(product, ['images', 0], {}), 'medium') || '', ratio: '1:1' } };

  const offer = getIn(product, ['offers', 'result', 0]);
  const bestPaymentOption = getIn(offer, ['bestPaymentOption']);
  const cartaoMarca = getIn(offer, ['cartaoMarca']);
  const salesPrice = getIn(offer, ['salesPrice']);
  const listPrice = getIn(offer, ['listPrice']);
  const payment = offer ? getPrice(bestPaymentOption, cartaoMarca, offerHighlight, offer) : {};
  const installment = offer ? renderInstallment(payment, offerHighlight) : '';
  const eventStamp = getEventStamp(offer);

  const renderTitle = () => {
    const discount = getIn(payment, ['discount'], {});
    const hasDiscount = discount > 0;

    return mountTitle(offerHighlight, payment, hasDiscount, discount, text);
  };

  const average = getIn(product, ['rating', 'average'], 0);
  const { recommendations } = getIn(product, ['rating']) || {};
  const Icon = getIcon(offerHighlight);
  const productSlug = getIn(product, ['slug'], '');

  return (
    <NavUI to={getLink(link, productId, getSellerId(sellerId), getOfferId(offer), productSlug)}>
      <Wrapper>
        <Title type={offerHighlight}>
          <WrapperIcon>{Icon && <Icon width={24} height={18} fill="#ffffff" />}</WrapperIcon>
          {renderTitle()}
        </Title>
        <WrapperProduct>
          <WrapperImage>
            <LazyPicture sources={sources} alt={product.name} />
            {eventStamp && (
              <WrapperImgEvent>
                <img src={eventStamp} alt="imagem do produto do evento em oferta" />
              </WrapperImgEvent>
            )}
          </WrapperImage>
          <ProductName numberOfLines={2}>{product.name}</ProductName>
          <WrapperStars>
            <Stars rating={average} />
            {average && ` (${recommendations})`}
          </WrapperStars>
          {offer && (
            <>
              {renderSalesPrice(payment.price, salesPrice) || renderListPrice(payment.price, listPrice)}
              <Price>{`R$ ${formatPrice(payment.price)}`}</Price>

              <Installment>{installment}</Installment>
              {payment.cashback && payment.cashback.rate > 1 && (
                <Cashback>
                  <AmeSymbolUI width={16} height={16} />
                  {`Receba R$ ${formatPrice(payment.cashback.value)}`}
                  <CashbackRate>{`(${payment.cashback.rate}% de volta)`}</CashbackRate>
                </Cashback>
              )}
            </>
          )}
          {!offer && <OutOfStockText>Infelizmente não temos esse produto em estoque</OutOfStockText>}
        </WrapperProduct>
      </Wrapper>
    </NavUI>
  );
};

Product.propTypes = {
  product: PropTypes.object,
  offerHighlight: PropTypes.string,
  productId: PropTypes.string,
  text: PropTypes.string,
};

const getBackground = type => {
  const colorMap = {
    'Frete Grátis': '#fabe0a',
    'Desconto de Preço': '#6cc947',
    Prime: '#ed003b',
    Cashback: '#ec0059',
  };

  return colorMap[type] || '#f80032';
};

const WrapperImgEvent = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 5;
  pointer-events: none;
  & img {
    max-width: 100%;
  }
`;

const Card = styled.div`
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
      position: absolute;
    `};
`;

const NavUI = styled(Nav)`
  display: flex;
  margin-left: 16px;
  margin-bottom: 16px;
  flex: 0 0 calc(25% - 16px);
  height: 458px;

  &:first-of-type {
    margin-left: 0;
  }
  &:nth-child(4n + 1) {
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  background: #fff;
  border-radius: 5px;
  margin: 15px 0px;
  height: 100%;
  width: 100%;
`;

const WrapperStars = styled.div`
  margin-bottom: 5px;
  display: flex;
`;

const CashbackRate = styled.span`
  color: #40cd28;
  margin-left: 4px;
`;

const WrapperIcon = styled.div`
  margin-right: 5px;
  display: flex;
`;

const Title = styled.div`
  background: ${props => getBackground(props.type)};
  height: 48px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #333;
  font-weight: bold;
`;

const Cashback = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: #333;
  display: flex;
  align-items: center;
`;

const Installment = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

const ProductName = styled(Text)`
  font-size: 15px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  max-width: 300px;
`;

const WrapperImage = styled.div`
  border-radius: 6px 6px 0 0;
  padding: 3px;
  min-height: 220px;
  margin: 0 40px;
`;

const WrapperProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 15px;
  font-size: 13px;
  position: relative;
`;

const Del = styled.del`
  font-weight: 700;
  min-height: 15px;
`;

const AmeSymbolUI = styled(AmeSymbol)`
  margin-right: 6px;
  fill: rgb(153, 153, 153);
`;

const OutOfStockText = styled.div`
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
`;

export default Product;
