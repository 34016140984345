import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { getIn } from 'seamless-immutable';
import PropTypes from 'prop-types';
import { withHotOffer } from '@catalogo/service-americanas-hot-offer/src/index';
import TimerCircle from '@catalogo/ui-americanas-desktop-timer';
import Product from './product';

export const ZionHotOffer = props => {
  const { publication, data, error, loading } = props;
  const { meta, alternativeTitle } = publication;

  if (loading || error) {
    return null;
  }

  const sources = {
    mobile: {
      src: publication.imageBannerMobile,
      // ratio: `${getIn(meta, ['imageBannerMobile', 'width'], 0)}:${getIn(meta, ['imageBannerMobile', 'height'], 0)}`,
    },
  };

  return (
    <Container>
      <ContainerHeader>
        <LazyPicture
          sources={sources}
          forcedWidth={getIn(meta, ['imageBannerDesktop', 'width'], 0)}
          alt={alternativeTitle}
        />
        <ContainerTimer>
          <TimerCircle publication={publication} />
        </ContainerTimer>
      </ContainerHeader>
      <ProductContainer>
        {getIn(data, ['curatedList', 'products'], []).map(
          productSpacey =>
            productSpacey.product && <Product key={`curated-${productSpacey.productId}`} {...productSpacey} />
        )}
      </ProductContainer>
    </Container>
  );
};

ZionHotOffer.propTypes = {
  publication: PropTypes.object,
};

const Container = styled.div`
  padding: 10px;
`;

const ContainerHeader = styled.div`
  /* position: relative;
  display: flex;
  max-height: 125px; */
`;

const ContainerTimer = styled.div`
  width: 284px;
  margin: 10px auto;
`;

const ProductContainer = styled.div`
  /* display: flex; */
  /* justify-content: center; */
`;

export default withHotOffer(ZionHotOffer);
