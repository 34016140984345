import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { getIn } from 'seamless-immutable';
import PropTypes from 'prop-types';
import { withHotOffer } from '@catalogo/service-americanas-hot-offer/src/index';
import TimerCircle from '@catalogo/ui-americanas-desktop-timer';
import { useHistory } from 'react-router-dom';
import { Product, LazyProduct } from './product';

export const ZionHotOffer = props => {
  const { publication, data, error } = props;
  const { meta, alternativeTitle } = publication;
  const history = useHistory();
  const hasLazyFlag = history?.location?.search?.includes('lazyHotOffer=true');

  if (error) {
    return null;
  }

  const sources = {
    desktop: {
      src: publication.imageBannerDesktop,
      ratio: `${getIn(meta, ['imageBannerDesktop', 'width'], 0)}:${getIn(meta, ['imageBannerDesktop', 'height'], 0)}`,
    },
  };

  return (
    <Container>
      <ContainerHeader>
        <LazyPicture
          sources={sources}
          forcedWidth={getIn(meta, ['imageBannerDesktop', 'width'], 0)}
          alt={alternativeTitle}
        />
        <ContainerTimer>
          <TimerCircle publication={publication} />
        </ContainerTimer>
      </ContainerHeader>
      <ProductContainer>
        {getIn(data, ['curatedList', 'products'], []).map(
          productSpacey =>
            productSpacey.product && (
              <div key={`curated-${productSpacey.productId}`}>
                {hasLazyFlag ? (
                  <LazyProduct key={`curated-${productSpacey.productId}`} {...productSpacey} />
                ) : (
                  <Product key={`curated-${productSpacey.productId}`} {...productSpacey} />
                )}
              </div>
            )
        )}
      </ProductContainer>
    </Container>
  );
};

ZionHotOffer.propTypes = {
  publication: PropTypes.object,
};

const Container = styled.div`
  margin: 20px 0;
`;

const ContainerHeader = styled.div`
  position: relative;
  display: flex;
  max-height: 107px;
  justify-content: space-between;
`;

const ContainerTimer = styled.div`
  align-self: center;
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
`;

export default withHotOffer(ZionHotOffer);
