import React from 'react';
import styled from 'styled-components';
import { useTimer } from '@catalogo/theme-timer';
import PropTypes from 'prop-types';
import CountDownElement from './count-down-element';

const Timer = ({ publication }) => {
  const timer = useTimer(publication.finalDate);

  return (
    <Wrapper>
      <CountDownElement
        timer={timer.hours}
        timerColor={publication.timerColor}
        timerBackgroundColor={publication.timerBackgroundColor}
        progressColor={publication.progressColor}
      />
      <Label timerColor={publication.timerColor}>h</Label>
      <CountDownElement
        timer={timer.minutes}
        timerColor={publication.timerColor}
        timerBackgroundColor={publication.timerBackgroundColor}
        progressColor={publication.progressColor}
      />
      <Label timerColor={publication.timerColor}>m</Label>
      <CountDownElement
        timer={timer.seconds}
        timerColor={publication.timerColor}
        timerBackgroundColor={publication.timerBackgroundColor}
        progressColor={publication.progressColor}
      />
      <Label timerColor={publication.timerColor}>s</Label>
    </Wrapper>
  );
};

Timer.propTypes = {
  finalDate: PropTypes.string,
};

export default Timer;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 22px;
  color: ${({ timerColor, theme }) => theme.fontColor || timerColor};
  margin: 0 10px 0 4px;
`;
