import React from 'react';
import { useQuery } from '@apollo/client';
import { getCuratedList } from './curated-list.graphql';

export const withHotOffer = WrappedComponent => {
  const EnhancedCategory = ({ publication }) => {
    const { data, error, loading } = useQuery(getCuratedList, {
      variables: {
        id: publication.curatedListId,
      },
    });

    return <WrappedComponent data={data} loading={loading} error={error} publication={publication} />;
  };

  return EnhancedCategory;
};
