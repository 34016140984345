export const getTimeFromSeconds = timer => {
  if (timer < 0) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }
  const seconds = Math.floor((timer / 1000) % 60);
  const minutes = Math.floor((timer / 1000 / 60) % 60);
  const hours = Math.floor((timer / (1000 * 60 * 60)) % 24);
  const days = Math.floor((timer / (1000 * 60 * 60 * 24 * 24)) * 24);

  return {
    days: `0${days}`.slice(-2),
    hours: `0${hours}`.slice(-2),
    minutes: `0${minutes}`.slice(-2),
    seconds: `0${seconds}`.slice(-2),
  };
};

export const parseDateTime = datetime => {
  try {
    datetime = datetime.split(' ');
    let [date, time] = datetime;

    date = date.split('/');
    const endDateTime = `${date[1]}/${date[0]}/${date[2]} ${time}`;
    const endDate = new Date(endDateTime);

    return endDate;
  } catch (e) {
    return new Date();
  }
};
